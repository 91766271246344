import React from "react";
import { Blog } from "./Blog";

const Blogs = ({instance}) => {
    return <div>
     
     <Blog instance={instance} edit={false}/>
     </div>
}

export { Blogs } 