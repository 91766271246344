const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

export default function createUrl(url) {
    console.log('createUrl',baseUrl + url)
    return baseUrl + url;
}

 function createUrlV1(url) {
    var _url = process && process.env && process.env.NODE_ENV === "development" ? '/' : baseUrl;
    if (url === undefined) return _url;
    return _url + url;
}