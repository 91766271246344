import React from 'react';
import _classes from './Options.scss';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

const Options = ({ routes, instance}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();

    const renderAttrs = (r) =>{
        if(r.requiresAuth && location.pathname === r.path) return ' active hide-sm';
        if(r.requiresAuth) return ' hide-sm';
        if(location.pathname === r.path) return ' active';
        return '';
    }

    return <div className='d-flex justify-content-center justify-content-lg-start flex-row align-items-end options'>

        {routes.map((r, i) => 
            r.requiresAuth && !isAuthenticated ? <span/> :
            <div key={i} className={renderAttrs(r)}>
                <i className={location.pathname === r.path ? r.icon + ' active' : r.icon} onClick={() => navigate(r.path)}></i>
                <span>{r.name}</span>
                </div>
            
                )}
            <div className='hide-sm' key={99}>
                <i className={isAuthenticated ? 'fa-solid fa-lock' : 'fa-solid fa-unlock'} onClick={()=>{
                     console.log('HERE', isAuthenticated);
                    
                    if(isAuthenticated){
                        instance.logoutPopup({
                            account: instance.getActiveAccount()
                            //mainWindowRedirectUri
                        });
                    }

                    instance.loginPopup({
                        ...loginRequest,
                        redirectUri:'/' //location.pathname
                    }).then((data)=>{
                        console.log('data',data);
                    })
                    .catch((error) => {
                        console.log('error response',error);
                        
                    });

                }}> </i>
              
            </div>
    </div>;
}

export {Options}

//onClick={() => }>