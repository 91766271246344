import React from 'react';
import _classes from './Contact.scss';
import { useNavigate } from "react-router-dom";

const Contact = () => {
    const navigate = useNavigate();

    return <div className='contact fade-in-block'>

        <div className='container'>
            <div className='row'>
                <div className='col-12'>
                    <h3>Contact</h3>
                    <p>
                    If you are considering attending counselling or psychotherapy sessions with me, please click the button below and complete the online form to get in touch.</p>
                    
                    <div className='button'
                        onClick={() => navigate('/questionnaire')}>Open enquiry form</div> 
        

                </div>
                
            </div>
        </div>

       


    </div>
}

export { Contact }

/*



 */