import React, { useEffect, useState } from 'react';
import httpService from '../services/httpService';
import _classes from './Stats.scss';
import { UnauthenticatedTemplate, AuthenticatedTemplate } from '@azure/msal-react';
import { loginRequest } from "../authConfig";

const Stats = ({instance}) => {

    const [data, setData] = useState(null);
    const [idData, setIdData] = useState(null);
    

    useEffect(() => {
        httpService.getStats((d) => {
            console.log('stats', d);
            setData(d);
        });
    }, []);


    const idClicked = (id) => {
        httpService.getStatsById(id, (d) => {
            console.log('stats', d);
            setIdData(d);
        });
    }

    return <div>
        <h1>Stats</h1>
        <UnauthenticatedTemplate>
            Not authorised. Please login in using the lock symbol above

            <div className='show-sm' key={99}>
                <i className='fa-solid fa-unlock' onClick={()=>{
                    
                    instance.loginPopup({
                        ...loginRequest,
                        redirectUri:'/' //location.pathname
                    }).then((data)=>{
                        console.log('data',data);
                    })
                    .catch((error) => {
                        console.log('error response',error);
                        
                    });

                }}> </i>
              
            </div>
        </UnauthenticatedTemplate>

<AuthenticatedTemplate>
        {data === null ? <div>Loading...</div> : <div>
            <label>Total visits : {data.total}</label>

        
            {data.byDate.map((x, i) => <DayTable key={i} data={x} onIdClick={idClicked } />)}
        </div>}

        {
            idData === null ? <div /> : <IdTable onClose={ ()=>setIdData(null) } data={idData }></IdTable>
        }
        </AuthenticatedTemplate>
    </div>
    
}

const IdTable = ({data, onClose }) => {

    return  <div className='idTable'>
                <div className='inner'>
                    <div className='id-close' onClick={onClose}>
                        <span>Close</span>
                        <i className="fa-regular fa-circle-xmark" ></i>
                    </div>
                    {data.map((x, i) => <div key={i} className='container id-row'>
                        <div className='row'>
                            <div className='col-5'>{x.dateStr}</div>
                            <div className='col-7'>
                                {x.pageVisits.map((y, i2) => <div className='container'>
                                    <div className='row '>
                                        <div className='col-6'>{y.time}</div>
                                        <div className='col-6'>{y.page}</div>
                                    </div>
                                </div>)}
                            </div>
                        </div>

                    </div>)}
                </div>
            </div>
      

      

}

const DayTable = ({data, onIdClick}) => {
    return <div className="stats-table">

         
        {

            data.ref.map((x, i) => <div key={i} className="container-fluid date-group">
                <div className="row stats-row">
                    <div className="col-4 col-lg-2 px-0 px-md-3">
                        {i === 0 && <span className="title">{data.dateStr}</span>
                           
                       }
                    </div>
                    <div className="col-4 col-sm-6 col-lg-3 px-0 px-md-3">
                        <a href='#' onClick={(e) => {
                            e.preventDefault();
                            onIdClick(x.ref)
                        }}>{x.ref}</a> 
                    </div>
                    <div className="col-4 col-sm-2 col-lg-1 px-0 px-md-3">
                        {x.count} {x.isUs && <span>*</span>}
                    </div>

                    <div className=".d-none .d-lg-block col-lg-6 px-0 px-md-3 stats-page-visits">
                        {x.pageVisits.map((p, pi) => (
                            <div key={pi } className="row">
                                <div className="col-4">{ p.time}</div>
                                <div className="col-8">{p.page}</div>
                                </div>


                            ))} 
                    </div>
                </div>
            </div>) 
           
        }
    </div>
}

export {Stats}