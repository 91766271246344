import React, {useState, useEffect} from 'react';
import _classes from './Questionnaire.scss';
import httpService from '../services/httpService';

const Confirmation = ({ message, onAccept, onCancel = null}) => {

    return <div className='confirmation'>
        <div className='inner'>
            <p>{message}</p>
            <div className={ onCancel!==null ? 'controls multiple' : 'controls single' }>
                <div className='button' onClick={onAccept}>Ok</div>
                {onCancel !== null && <div className='button' onClick={onCancel}>Cancel</div>}
            </div>
        </div>
    </div>
}


const Radio = ({options, selected, onUpdate}) => {
    const setOption = (o) => {
       
        if (onUpdate !== undefined) onUpdate(o);
    }

    return <div className='radio' >

        {options.map((o, i) => (<div key={i}>

            {o}
            <i className={selected === i ? 'fa-regular fa-circle-dot' : "fa-regular fa-circle"} onClick={()=>setOption(i)}></i>
        </div>))}

        </div >;
}

const SpecializedInput = ({ field, validationRule, onUpdate, multiline = false, showRequired=false }) => {
    const [f, setF] = useState(field);
    const [isValid, setIsValid] = useState(false);

    const validate = (val) => {

        var passed = val !== '' && regex.test(val);

        setF(val);
        setIsValid(passed);
        onUpdate(passed ?val:'');
       
        return passed;
    };

    useEffect(() => {
        setIsValid(validate(field));
    }, []);
   
    const regex = new RegExp(validationRule);

    return multiline === false ? <input className={!showRequired ? 'valid' : 'invalid'} value={f} onChange={(e) => validate(e.target.value)} />
        : <textarea className={!showRequired ? 'valid' : 'invalid'} value={f} onChange={(e) => validate(e.target.value)} />
}


const Questionnaire = ({ onClose }) => {

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showCheckJunkConfirmation, setShowCheckJunkConfirmation] = useState(false);
 
    const enquirySendStateEnum = {
        notSent: 0,
        sending: 1,
        sentOk:2,
        failed:3
    };
    const [enquirySendState, setEnquirySendState] = useState(enquirySendStateEnum.notSent);


    const [form, setForm] = useState({
        name: '',
        phone: '',
        email: '',
        occupation: '',
        reasons: '',
        medical: '',
        availability: '',      
        howDidYouHear: '',
        howYouWouldLikeToAttend: null,
        age:''
    });

    const update = (what) => {
        console.log('update', what);
        var newObj = { ...form, ...what };
        setForm(newObj);
    }


    const allFieldsComplete = () => {
        return form.email !== ''      
    }


    const onSubmit = () => {

        setEnquirySendState(enquirySendStateEnum.sending);

        httpService.send(form, (data) => {
            setEnquirySendState(enquirySendStateEnum.sentOk);

            httpService.register('Questionnaire sent');

            setShowCheckJunkConfirmation(true);
            //setTimeout(() => onClose(), 2000);

        }, (err) => {
            console.log(err);
            setEnquirySendState(enquirySendStateEnum.failed);
        });
    }

    const renderSendState = () => {
        
        switch (enquirySendState) {
            case enquirySendStateEnum.notSent: return <div className={allFieldsComplete() ? 'button valid' : 'button'}
                onClick={() => {
                    if (!allFieldsComplete()) return;

                    onSubmit(form);
                }
                }>Send Enquiry</div>;
            case enquirySendStateEnum.sending: return <div className='sending'><p>Sending enquiry..</p></div>;
            case enquirySendStateEnum.failed: return <div className='failed'>
                <p>Apologies we could not send this enquiry. If you could try contacting us on isobel@talkingroots.co.uk that
                would be appreciated. Sorry for the inconvenience.
                    </p> </div>;
            case enquirySendStateEnum.sentOk: return <div className='success'>
                <p>Thankyou we received your enquiry. We have sent an email to {form.email} with a copy of your enquiry.</p> </div>;
            default: return <></>    
    }
    }


    return <div className='questionnaire fade-in-block'>

    <div className='inner '>
        <div className='container-fluid main'>
            <div className='row'>
                <div className='col-12'>
                    <i className="close fa-regular fa-circle-xmark" onClick={()=>setShowConfirmation(true)}></i>                  
                    
                    <h3>Client Enquiry Information</h3>
                    
                    <p>
                        Please provide the following information. 
                    </p>
                    <p>
                        Please note that I comply with all GDPR requirements.
                        All data will treated in confidence, held securely and under no circumstances will be passed to a third party. 

                    </p>

                </div>
            </div>

            <div className='row'>
                <div className='col-3 label'>Name</div>
                <div className='col-9'>
                    <SpecializedInput field={form.name}
                        validationRule='[a-zA-Z ]{3,}'
                      
                        onUpdate={(v) => update({ name: v })} />
                
                </div>
            </div>

            <div className='row'>
                <div className='col-3 label'>Phone number</div>
                <div className='col-9'>
                    <SpecializedInput field={form.phone}
                        
                       
                        validationRule='^(\(\+44\)|\+44|0)((\d{10,11})|(\d{2} ?\d{8,9})|(\d{3} ?\d{7,8})|(\d{4} ?\d{6,7}))$' onUpdate={(v) => update({ phone: v })} />

                    
                </div>
            </div>

            <div className='row'>
                <div className='col-3 label'>
                    * Email
                    
                    </div>
                <div className='col-9'>
                    <SpecializedInput field={form.email} 
                        showRequired={ form.email === ''}
                        validationRule='^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$'
                        onUpdate={(v) => update({ email: v })} />
                    <div className='small'>(We will email you a copy of your enquiry)</div>
                </div>
                
            </div>

            <div className='row'>
                <div className='col-3 label'>
                    Age
                    </div>
                <div className='col-9'>
                    <SpecializedInput field={form.age} 
                     
                        validationRule='^\d{2,3}$'
                        onUpdate={(v) => update({ age: v })} />
                    <div className='small'>(You must be 18 or over to access our services)</div>
                </div>

            </div>

            <div className='row'>
                <div className='col-4 col-sm-3 label'>Occupation</div>
                <div className='col-8 col-sm-9'>
                    <SpecializedInput field={form.occupation}  validationRule='^.*$'
                    onUpdate={(v) => update({ occupation: v })} />
                </div>
            </div>

            <div className='row'>
                <div className='col-12 label'>Please briefly describe your reasons for wanting to attend psychotherapy
</div>
                <div className='col-12'>
                    <SpecializedInput field={form.reasons}  validationRule='^.*$' multiline
                    onUpdate={(v) => update({ reasons: v })} /></div>
            </div>


            <div className='row'>
                <div className='col-12 label'>If you are on medication for any condition, please provide details</div>
                <div className='col-12'>
                    <SpecializedInput field={form.medical}  validationRule='^.*$' multiline
                        onUpdate={(v) => update({ medical: v })} />
                </div>
                </div>
            

            <div className='row'>
                <div className='col-12 label'> Availability. Please indicate which days and times you would be available to attend weekly sessions. I offer a variety of appointments between 7am and 8pm, Monday to Friday.
</div>
                    <div className='col-12'>
                    <SpecializedInput field={form.availability}  validationRule='^.*$'
                    onUpdate={(v) => update({ availability: v })} /></div>
            </div>
            

            <div className='row'>
                <div className='col-12 col-md-6 col-lg-4 label'> How would you like to attend your sessions?</div>
                <div className='col-12 col-md-6 col-lg-8'>
                    <Radio selected={form.howYouWouldLikeToAttend}
                        options={['Face to Face', 'Zoom', 'Phone']}
                        onUpdate={(v) => update({ howYouWouldLikeToAttend: v })} />
                </div>
            </div>

            <div className='row'>
                <div className='col-12 col-md-6 col-lg-4 label'>How did you hear about me?</div>
                <div className='ol-12 col-md-6 col-lg-8'>

                    <select onChange={e => update({ howDidYouHear: e.target.value })}>
                        <option value=""></option>
                        <option value="Counselling Directory">Counselling Directory</option>
                        <option value="Psychology Today">Psychology Today</option>
                        <option value="Google Search">Google Search</option>
                        <option value="Personal Recommendation">Personal Recommendation</option>
                        <option value="Business Card">Business Card</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
        </div>
            

            <div className='row'>
                <div className='col-12'>

                    <p>Thank you for completing this form. I will aim to get back to you within 24 hours.</p>
                </div>
            </div>

            <div>
             
            </div>
            
            
            
                
        </div>

        <div className='save-banner'>
        <div className='junk'> 
            <i className="fa-solid fa-circle-info"></i>
            Sometimes emails do end up in the 'Junk' folder, so if you have not received a reply from me please check this folder.
        </div>
                 
        {renderSendState()}
        </div>
    </div>

        {showConfirmation === true
            && <Confirmation onAccept={() => onClose()}
            onCancel={()=>setShowConfirmation(false)}
                message='Please confirm that you wish to close this form. Any information you have entered will be lost?' />}

        {showCheckJunkConfirmation === true
            && <Confirmation onAccept={() => onClose()}
            message='I will aim to get back to you within 24 hours. Please check your junk mail if you haven’t heard from me.' />}
    </div>;
}

export { Questionnaire, Confirmation }

