import React, { Component } from 'react';
import _classes from './Layout.scss';

const Logo = () => <div className='logo'>
    <div className='logo-image'></div>
    <div className='logo-text'>
        <span>
            Talking
            Roots 
            Psychotherapy
        </span>
       
    </div>
</div>

const Layout = (props) => {
    return <div className='layout'>

        <div className='container-fluid banner p-2'>
            <div className='row'>
                <div className='logo-sm col-12 col-sm-4 col-md-4 col-lg-3'>  <Logo></Logo></div>
                <div className='col-12 col-sm-8 col-md-8 col-lg-9'> { props.left}</div>
              
            </div>
        </div>
       
        <div className='container'>
            <div className='row mt-4'>      
                <div className='col-lg-9  mt-lg-5'>
                    {props.children}
                </div>
                <div className='col-lg-3  mt-lg-5'>{props.right}</div>
            </div>
        </div>
       
       

    </div>
}

export { Layout }