
import axios from 'axios';
import createUrl from './urlHelper';

function register(url, callbackFn, errorFn)
{
    let id = localStorage.getItem("visitorId");

    console.log('id', id);

    if (id === null) {
        var newId =  Math.random().toString(16).slice(2);
        console.log('newId', newId);

        localStorage.setItem('visitorId', newId)

        id = newId;
    }

    var obj = { page: url, id:id };
    console.log(obj);
    axios.post(createUrl('api/register'), obj).then((d) => {
            callbackFn(d.data);
        }).catch((e) => {
            console.log(e);
            if (errorFn) errorFn(e.response.data)
        });
    }


function send(message, callbackFn, errorFn) {
    axios.post(createUrl('api/message'), message).then((d) => {
        callbackFn(d.data);
    }).catch((e) => {
        console.log(e);
        if (errorFn) errorFn(e.response.data)
    });
}

function getStats(callbackFn, errorFn) {
    axios.get(createUrl('api/getStats')).then((d) => {
        callbackFn(d.data);
    }).catch((e) => {
        console.log(e);
        if (errorFn) errorFn(e.response.data)
    });
}

function getBlogs(callbackFn, errorFn) {
    axios.get(createUrl('api/blogs')).then((d) => {
        callbackFn(d.data);
    }).catch((e) => {
        console.log(e);
        if (errorFn) errorFn(e.response.data)
    });
}

function getStatsById(id, callbackFn, errorFn) {
    axios.get(createUrl(`api/getStats/${id}`)).then((d) => {
        callbackFn(d.data);
    }).catch((e) => {
        console.log(e);
        if (errorFn) errorFn(e.response.data)
    });
}

function saveBlog(token, blog, callbackFn, errorFn) {
    axios.post(createUrl('api/blogs'), blog, {headers:{'Authorization':`Bearer ${token}`}})
    .then((d) => {
        callbackFn(d.data);
    }).catch((e) => {
        console.log('error', e);
        if (errorFn) errorFn(e.response.data)
    });
}


function removeBlog(token, file, callbackFn, errorFn) {
    axios.delete(createUrl(`api/blogs/${file}`), {headers:{'Authorization':`Bearer ${token}`}})
    .then((d) => {
        callbackFn(d.data);
    }).catch((e) => {
        console.log('error', e);
        if (errorFn) errorFn(e.response.data)
    });
}
const funcs = {
    send,
    register,
    getStats,
    getStatsById,
    getBlogs,
    saveBlog,
    removeBlog
}

export default funcs;
