import React from 'react';
import _classes from './Crisis.scss';

const Crisis = () => {

	const data = [
		{
			section: 'Telephone helplines',
		data: [
				{
					name: 'NHS 24 hour triage mental health team',
					contact: 'Tel: 111',
					info:'They provide telephone based mental health screening.'
			},
				{
					name: 'Samaritans',
					contact: 'Tel: 116 123, Email: jo@samaritans.org. ',
					info: 'Use the self-help app. Call anytime to speak to someone in a confidential setting.'
				}
				,
				{
					name: 'Shout',
					contact: 'Text HANTS to 85258',
					info: 'Text service run by trained volunteers who are available 24/7 to listen and support you to get to a calmer and safer space. This is a free service that will not appear on your phone bill. '
				}
				,
				{
					name: 'Solent Mind Support Line',
					contact: 'Tel: 023 8017 9049',
					info: 'Available to anyone in Hampshire and Isle of Wight who feels low, anxious and needs someone to talk to. Open weekdays 9am to 7pm, weekdays 10am to 2pm.'
				}
				,
				{
					name: 'Kooth',
					contact: 'https://www.kooth.com/',
					info: 'Online counselling and support for young people aged between 11 and 25 years. Available weekdays between 12pm and 10pm and weekends between 6pm and 10pm.'
				}

			]
		},
		{
			section: 'Crisis Team',
			data: [
				{
					name: 'Southern Care Foundation Trust',
					contact: 'Tel: 01256 817718, Out of hours: 01962 897732, westamht@southernhealth.nhs.uk',
					info: 'Parklands Hospital, Aldermaston Road, Basingstoke, RG24 9RH; The Crisis Resolution Home Treatment Team provide intensive support for people experiencing a mental health crisis. Referrals can be made via GP, other health services, emergency services, people who are known to services and agencies other than health services that support people with mental health problems. '
				}
			]
		},
		{
			section: 'Drop in centre',
			data: [
				{
					name: 'The Safe Haven',
					contact: '3 Vyne Road, Basingstoke',
					info: 'Drop in centre offering help to those in self-defined crisis. Provides emotional and practical support to adults experiencing a mental health crisis. Open between 6pm and 10pm, 365 days a year. '
				}
			]
		},
		{
			section: 'Websites',
			data: [
				{
					name: 'Mental Wellbeing Hampshire: ',
					contact: 'https://www.hants.gov.uk/socialcareandhealth/publichealth/mentalwellbeinghampshire'
				},
				{
					name: 'Mind',
					contact: 'https://www.mind.org.uk/'
				},
				{
					name: 'NHS',
					contact: 'https://www.nhs.uk/mental-health'
				}
			]

		}
	];

	const parse = (s, c=',') => {
		var parts = s.split(c);

		return <React.Fragment>
			{
				parts.map((p, pi) => <span key={pi}>{ p.trim() }</span>)
            }
		</React.Fragment>;

    }

    return <div className='crisis fade-in-block'>
        <h3>Crisis Contacts</h3>
        <p>
If you are experiencing suicidal thoughts and are at immediate risk of harming yourself, call 999 or take yourself to A&E. 
        
        </p>
          <p>
            If you are safe but in need of mental health support, see the organisations below.
             
        </p>
         
		{
			data.map((d, i) => (
				<div key={i} className='container-fluid g-0 mb-3'>
					<h3>{d.section}</h3>

					{d.data.map((x, ix) => (
						<div key={ix} className='row g-0 mb-1'>

							<div className='col-12 col-lg-4'>{x.name}</div>
							<div className={x.info === undefined ? 'col-12 col-lg-8' : 'col-12 col-lg-4'}>{parse(x.contact)}</div>
							{
								x.info === undefined ? <div/> :
									<div className={ 'col-12 col-lg-4'}>{parse(x.info, ';')}</div>
                            }
							
						</div>
					))}
				</div>

				))
        }

    </div>;
}

export { Crisis }