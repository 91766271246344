import React from 'react';


//width="200" height="200" 
const Map = () => <div className='map'>
    <iframe width="425" height="350" frameBorder="0" scrolling="no" marginHeight="0"
        marginWidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=-1.1552810668945315%2C51.25949786107797%2C-1.1302399635314944%2C51.267755392432406&amp;layer=mapnik&amp;marker=51.263626812197366%2C-1.1427605152130127" >
    </iframe>
    <div className='open-larger'><a href="https://www.openstreetmap.org/?mlat=51.2636&amp;mlon=-1.1428#map=16/51.2636/-1.1428">View Larger Map</a></div>   
</div>;

export { Map }
