import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../authConfig";
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import Editor from './Editor';
import httpService from '../services/httpService';
import _classes from './Blog.scss';
import "quill/dist/quill.core.css";
import 'quill/dist/quill.snow.css';
import {useParams} from "react-router-dom";

const Blog = ({instance, edit = false, props }) =>{
    const navigate = useNavigate();
    const itemEls = useRef([]);

    let { id } = useParams();
   
    const [blogs, setBlogs] = useState([]);

    useEffect(()=>{
     

      httpService.getBlogs((data)=>{
       
        setBlogs(data.map(x=>({...x, editing:false})));

        if(id !== undefined && id > 0 && id <= data.length){
          setTimeout(()=>{
            var idx = id;
            
           
            itemEls.current[idx].scrollIntoView({ behavior: "smooth" });
          }, 500);
        }
       
      })
    }, []);

    const addNewBlog = () => {
      var check = blogs.find(x=>x.file === '');

      if( check !== undefined) return false;

      var newBlogList = [{file:'', content:'', editing:true}, ...blogs];
      setBlogs(newBlogList);
    };


    const callWrapper = (fn) => {
      instance.acquireTokenSilent(loginRequest)
            .then(tokenResponse => 
            {
                var token = tokenResponse.accessToken;
            
                fn(token);

            }).catch(error => {

                alert('Oooops could not save..');

                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return instance.acquireTokenRedirect(loginRequest)
                }
            });
    }


    const handleSave = (idx, blog) => {

        callWrapper((token)=>{
          httpService.saveBlog(token, blog, (data)=>{

            var clone = [...blogs];
            clone[idx] = data;
            clone[idx].editing = false;

            console.log('Updated '+idx, clone[idx]);

            setBlogs(clone);
        });
        });
    }

    const editBlog = (idx) => {
      var clone = [...blogs];
      clone[idx].editing = true;
      setBlogs(clone);
    }

    const removeBlog = (idx) => {
      var file = blogs[idx].file;

      var filtered = blogs.filter(x => x.file !== file);

      setBlogs(filtered);

      callWrapper((token)=>{
        httpService.removeBlog(token, file, (data)=>{
      });
      });

    }

 

    return  <div>

        <div className="d-flex align-items-center">
           {edit === true && <div className='button ml-auto' onClick={()=>addNewBlog()}>Add new</div>}
        </div>

        <div>
       
         {
           blogs.length === 0 ? <div>There are no blogs</div> :
            blogs.map((x,i)=>(edit && (x.editing || x.file === '') 
              ? <div className="blog-entry">
               <Editor key={i} file = {x.file} content={x.content}  handleSave={(content) => {

                var newBlog = {
                  file: x.file, 
                  content:content}
                  ;
                handleSave(i, newBlog);

              }}/>
              
              </div> 
            :  <div key={i} data-x={x.content}
            ref={(element) => itemEls.current[blogs.length - i] = element}
                className="blog-entry ql-editor">
                      <div className="ql-container ql-snow" style={{borderWidth: 0}} 
                      dangerouslySetInnerHTML={{__html:x.content}}></div>
                      {edit === true && <div className="controls">
                        <div class='button' onClick={()=>editBlog(i)}>Edit</div>
                        <div class='button' onClick={()=>removeBlog(i)}>Remove</div>
                      </div>}
                      <div className="blog-number" onClick={()=>navigate('/blogs/'+(blogs.length - i))}>#{blogs.length - i}</div>
                </div> ))
         }

       
        </div>
        


</div>

}

export { Blog };