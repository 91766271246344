import React from 'react';
import _classes from './ContactCard.scss';

const ContactCard = () => {

    
    const options = [
        { method: 'Address', at: ['Worting House', 'Church Lane', 'Basingstoke', 'RG23 8PY'], icon: 'fa-solid fa-location-dot' },
        { method: 'Telephone', at: '07766 005022', icon: 'fa-solid fa-phone-volume' },
        { method: 'E-mail', at: 'isobel@talkingroots.co.uk', icon: 'fa-regular fa-envelope' },
    ];

    return <div className='contact-card'>

        <div className='container-fluid p-0'>
            <div className='row'>
                {options.map((o, ix) => (<div key={ix} className='col-12 col-md-4 col-lg-12 d-flex'>
                    <i className={o.icon} />
                    <div className='arr'>
                        {Array.isArray(o.at) ? o.at.map((l, i) => <span key={i}>{l}{i === o.at.length -1 ? '':','} </span>) : <span>{o.at}</span>}

                    </div>
                </div>))}
            </div>
            
            <div className='row appoint'>
                Appointments between 7am and 8pm Monday to Friday.
            </div>
            <div className='row bacp'>

            </div>
        </div>

        
    </div>;
}


export { ContactCard }