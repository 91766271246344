import React, { Component, useEffect } from 'react';
import _classes from './Home.scss';
import { useNavigate, useParams } from "react-router-dom";
import { Articles } from './Articles';

const Home = () => {
    const navigate = useNavigate();


    

    return <div className='home fade-in-block'>

        <div className='me'></div>

        <h3>Counselling and Psychotherapy in Basingstoke with Isobel Brooks</h3>

        <p>
        I am a BACP Accredited Counsellor and Psychotherapist offering private counselling sessions in Basingstoke, Hampshire or online. 
</p> 
  <p>
I can help you to make sense of difficult feelings or problems in relationships that you are experiencing. You may be aware of repeated patterns of behaviour that seem impossible to break, or you may be feeling overwhelmed, anxious or angry. Whatever the issue, I can help you to explore and identify the root cause of your difficulties. This can provide you with a greater understanding of yourself and an increased sense of wellbeing.
</p>
   <p>
I am both a Counsellor and Psychodynamic Psychotherapist. I specialise in open-ended work with adults, although I can also offer short term sessions too. We will work together to identify your unconscious processes and your automatic thought patterns. The problems we experience as adults are often related to our childhood, and together we can carefully navigate through your life to help you gain clarity and insight into yourself.
</p>
   <p>
I understand how difficult it is to ask for help and that approaching a Counsellor or Psychotherapist is a big step. I provide a warm and empathic approach at a confidential and quiet location situated 2 miles from Basingstoke town centre. I can also provide sessions online. I believe that it is important to move at a pace that you are comfortable with and together we will work collaboratively to explore and understand the material that you bring.
</p>
        <Articles />
        <p className='important'>
            Please note that I do not offer a crisis service. If you are experiencing suicidal thoughts and require immediate
            mental health support, please click on this <span className='link' onClick={() => navigate('/crisis') }>link</span> for services who can help you. 

        </p>
      
        
    </div>
}

export {Home}