import { React } from 'react';
import data from './Articles.json';
import _classes from './Articles.scss';
import httpService from '../services/httpService';
import { useLocation } from 'react-router-dom';

const Articles = () => {

    const open = (a) => {

        httpService.register(a.title, () => {
            console.log('registered', a.title);
        }, (e) => {
            console.log('error returned, wait and resend', e);
        }
        );

        window.open(a.link, '_blank', 'noreferrer');

        
    }
   
    return <div className='articles'>

        <div className='article-title'>Most recent published articles by Isobel Brooks</div>
        
        <div className="article-items">
            {


              data.map((a, i) => (<div key={i} className='article carousel-cell'
                    onClick={() => open(a)}>
                    <img src={`${process.env.PUBLIC_URL}/${a.imageUrl}`} />
                    <span>{a.title}</span>
                </div>
                ))

               
            }
        </div>
        
      
    </div>;
}

export { Articles }

