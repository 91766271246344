import React from 'react';
import _classes from './About.scss';

const About = () => {

    return <div className='about fade-in-block'>

        <div className='container'>
            <div className='row'>
               
                <div className='col-12'>
                    <div className='me'></div>
                    <h3>About me</h3>
<p>
                    I am an experienced BACP Accredited Counsellor and Psychotherapist with the following qualifications:
                    </p>
                    <p>
                        <bullet is="x3d">&bull; MSc in Psychodynamic Counselling and Psychotherapy</bullet>
                        <bullet is="x3d">&bull; BSc (hons) degree in Psychology</bullet>
                        <bullet is="x3d">&bull; Diploma in Counselling</bullet>
                        <bullet is="x3d">&bull; Certificate in Counselling</bullet>
                    </p>

<p>
Prior to starting my private practice I worked for Basingstoke Counselling Service for four years. I also
    continue to provide EAP counselling (Employee Assistance Program) on behalf of AXA Health.
</p>
<p>
I work with clients who present with a wide range of issues. These include anxiety, depression, low self-esteem, trauma, abuse, relationship difficulties, bereavement, stress, loneliness and eating disorders.
</p>
<p>
Before we agree to work together in an open-ended way, I want to ensure that you will benefit from counselling and that I am the right counsellor for you. I will therefore treat our first 3 meetings as introductory sessions. This will give you an experience of counselling and we can think about if this approach suits you. If we decide that alternative type of counselling, or a different counsellor would be a better fit, I can help you to find someone suitable to work with you.
</p>


                   
                </div> </div>
        </div>

        

       

    </div>
}

export { About }
