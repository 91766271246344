import { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import BlotFormatter from 'quill-blot-formatter';
import "quill/dist/quill.core.css";
import 'quill/dist/quill.snow.css';

const Editor = ({content, handleSave}) => {
  const { quill, quillRef, Quill } = useQuill({
    //modules: { blotFormatter: {} }
  });

  if (Quill && !quill) {
    var ColorClass = Quill.import('attributors/class/color');
    var SizeStyle = Quill.import('attributors/style/size');
    //Quill.register(ColorClass, true);
    //Quill.register(SizeStyle, true);
    Quill.register('modules/blotFormatter', BlotFormatter);
  }

  useEffect(() => {
    if (quill) {

        let initialContent = quill.clipboard.convert(content);
        quill.setContents(initialContent, 'silent');
    }
  }, [quill, Quill]);

  return (
    <div className=''>
      <div ref={quillRef} />
      <div className='controls'>
      <div class='button' onClick={()=>{
        var justHtml = quill.root.innerHTML;
      
        if(!handleSave)return;
        
        handleSave(justHtml)

      }}>Save</div>
    </div>
    </div>
  );
};

export default Editor;
