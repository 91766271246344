import React, { useEffect, useState } from "react";
import { UnauthenticatedTemplate, AuthenticatedTemplate } from '@azure/msal-react';
import { Blog } from "./Blog";


const Admin = ({instance}) => {


    return  <div>

       <AuthenticatedTemplate>
         <h1>Blog Editor</h1>
          <Blog instance={instance} edit={true}/> 
       </AuthenticatedTemplate> 
       <UnauthenticatedTemplate>
            <div>Not authorized. Please login using the lock symbol above</div>
        </UnauthenticatedTemplate>
</div>


    
}

export {Admin}

       