import React, { useEffect, useState } from 'react';
import _classes from './Privacy.scss';
import raw from '../data/privacy.txt';

const Privacy = () => {

    const [statement, setStatement] = useState(null);


    useEffect(() => {

        fetch(raw)
            .then(r => r.text())
            .then(text => {

                var lines = text.split('\n');
                setStatement(lines);
              
            });

    },[])


    const renderLine = (l, i) => {
        if (l.substring(0, 1) === '*') return <h3 key={i}>{l.slice(1)}</h3>;
        return <span key={i}>{l}</span>;
    }

    return <div className='privacy fade-in-block'>
       
       
        {statement !== null && statement.map((l, i) => renderLine(l, i))}
    </div>
};

export {Privacy}
