import React from 'react';
import { Map } from './Map';
import _classes from './Location.scss';

const Location = () => {

    return <div className='location fade-in-block'>
       

        <h3>Location</h3>
        <div className='house'></div>
       <p>
       My consulting office is situated in the beautiful and peaceful location of Worting House, Basingstoke, Hampshire. RG23 8PY. 
       This is about 2 miles away from Basingstoke Town Centre and close to the village of Oakley. 
       The following postcodes are in close proximity to the premises: RG21, RG22, RG24, RG25 and RG26.
</p>
<p>
Worting House is also easily accessible from locations throughout Hampshire, Berkshire and Surrey. 
These include Reading, Newbury, Farnborough, Aldershot and Winchester. My consulting room is a short drive from junction 6 of the M3. 
</p>
<p>
There is a large free car park and a bus stop within a 5 minute walk of the house.
</p>
<p>
I am also able to conduct sessions online using Zoom, or over the phone.
</p>
       
       
        <Map />
    </div>
}

export { Location }
