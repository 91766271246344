import React, { useEffect, useState } from 'react';
import {
    Routes,
    Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import './custom.css';
import { Home } from './components/Home';
import _classes from './App.scss';
import { Options } from './components/Options';
import { ContactCard } from './components/ContactCard';
import { FaQ } from './components/FaQ';
import { Contact } from './components/Contact';
import { About } from './components/About';
import { Location } from './components/Location';
import { Fees } from './components/Fees';
import { Crisis } from './components/crisis';
import { Privacy } from './components/Privacy';
import { Questionnaire } from './components/Questionnaire';
import { useNavigate } from "react-router-dom";
import { Stats } from './components/Stats';
import httpService from './services/httpService';
import { useLocation } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { Admin } from './components/Admin';
import { Blogs } from './components/Blogs';


const routes = [
    { name: 'Home', path: '/', component: <Home />, icon: 'fa-solid fa-house-chimney', isDefault: true },
    { name: 'About', path: '/about', component: <About />, icon: 'fa-regular fa-circle-user' },
    { name: 'Fees', path: '/fee', component: <Fees />, icon: 'fa-regular fa-credit-card' },
    { name: 'Location', path: '/location', component: <Location />, icon: 'fa-solid fa-location-dot' },
    { name: 'FAQs', path: '/faq', component: <FaQ />, icon: 'fa-regular fa-circle-question' },
    { name: 'Contact', path: '/contact', component: <Contact />, icon: 'fa-regular fa-envelope' },
    { name: 'Blogs', path: '/blogs', component: <Blogs />, icon: 'fa-brands fa-blogger' },
 
    { name: 'Stats', path: '/stats', component: <Stats />, icon: 'fa-solid fa-chart-simple', requiresAuth:true  },
    { name: 'Admin', path: '/admin', component: <Admin />, icon: 'fa-solid fa-marker', requiresAuth:true },
];

const App = ({instance}) => {
  
    const navigate = useNavigate();
  
    const [registered, setRegistered] = useState(false);
    const location = useLocation();

    function register(u) {
        if (registered) return;
        httpService.register(u, () => {
            console.log('registered', u);
            }, (e) => {
                console.log('error returned, wait and resend', e);             
            }
        );
    }

    useEffect(() => {

        console.log('register: ', location.pathname);
        if (location.pathname === '/stats') return;

        register(location.pathname);
        }, [routes, location.pathname]);
   
    
    return (
        <MsalProvider instance={instance}>
            <Layout 
                left={<Options routes={routes} instance={instance}/>}
                right={<div>
                    <ContactCard />
                
                    {location.pathname === '/faq' && <div className='stats-button'>
                        <i className="fas fa-chart-bar" onClick={() => navigate('/stats')}></i>
                    </div>}
                </div>}>

                <Routes>
                    {routes.map((r, i) => (<Route 
                        key={i} exact 
                        path={r.path} 
                        element={React.cloneElement(r.component, { instance:instance })}></Route>))}
                    <Route exact path={'/crisis'} element={<Crisis />} />
                    <Route exact path={'/privacy'} element={<Privacy />} />
                    <Route exact path={'/stats'} element={<Stats />} />
                    <Route exact path={'/blogs/:id'} element={<Blogs />} />
                    <Route exact path={'/questionnaire'} element={<Questionnaire onClose={() => navigate('/contact')} />} />
                </Routes>

            </Layout>
        </MsalProvider>
    );

}

export default App;
